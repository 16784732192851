//default layout
import React, { useEffect } from 'react';
import { classPrefix } from '../../../config/appConfig';
import { connect } from 'react-redux';
import Header from '../header/Header';
import Gradients from '../color/Gradients';

import dynamic from 'next/dynamic';
import { useDispatch } from 'react-redux';
import { siteSettingGetData } from '../../../redux/actions/siteSettingActions';
const Footer = dynamic(() => import('../footer/Footer'), {
  loading: () => {
    return ""
  }, ssr: false
});

const DefaultLayout = ({ children }) => {

  const dispatch = useDispatch();

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      dispatch(siteSettingGetData());
    }

    return () => {
      mounted = false;
    }
  }, []);


  return (
    <div id={`__${classPrefix}__`}>
      <Header />
      <div id="__layout__">
        <main className="main">{children}</main>
        <Footer />
      </div>
      <Gradients />
    </div>
  );
}
export default connect(null, null)(DefaultLayout);