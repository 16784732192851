import { CONTACT_FAIL, SITE_SETTING_SUCCESS, CONTACT_SUCCESS } from "../type"
import axios from 'axios';
import { apiUrl } from '../../config/apiConfig';
import router from "next/router";

export const siteSettingGetData = () => async (dispatch) => {
  axios.get(apiUrl + '/api/siteSetting')
    .then((response) => {
      if (response.data.data) {
        dispatch({
          type: SITE_SETTING_SUCCESS,
          payload: response.data.data,
        });
      }

    })
    .catch((error) => {
      // console.log('error', error);
    });
}



export const contactAdded = (formData) => async (dispatch) => {


  let bodyFormData = new FormData();
  bodyFormData.append('email', formData.email ? formData.email : "");
  bodyFormData.append('name', formData.name ? formData.name : "");
  bodyFormData.append('message', formData.message ? formData.message : "");

  axios({
    method: "post",
    url: apiUrl + "/api/postContact",
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  })
    .then((response) => {
      router.push('/')
      // console.log("post contact",response);
      dispatch({
        type: CONTACT_SUCCESS,
        message: "contact submit"
      });

    })
    .catch((error) => {
      // console.log(error);

      dispatch({
        type: CONTACT_FAIL,
        payload:
          error.response && error.response.data
            ? error.response.data
            : error.message,
      });

    });

};